<script setup lang="ts">
import { MainPages } from './types'

function onRedirectHome() {
  navigateTo('/')
}
</script>
<template>
  <main class="flex flex-col min-h-screen">
    <header
      class="flex w-full items-center justify-between pt-6 pb-4 z-40 container mx-auto"
    >
      <NuxtLink :to="{ name: MainPages.Index }">
        <AssetLogoWithText />
      </NuxtLink>
    </header>

    <section class="flex flex-1 justify-center items-center">
      <div>
        <h2 class="text-3xl text-center font-semibold">
          {{ $t('notFound404.title') }}
        </h2>

        <div class="mt-6 text-center">
          <p>{{ $t('notFound404.description') }}</p>
          <AppButton class="mt-6 mx-auto" is-primary @click="onRedirectHome">
            {{ $t('notFound404.backToHome') }}
          </AppButton>
        </div>
      </div>
    </section>

    <LayoutTheFooter />
  </main>
</template>
