<script lang="ts" setup>
const uuid = `${Math.random()}`
</script>

<template>
  <svg
    width="143"
    height="27"
    viewBox="0 0 143 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#logoWithText-${uuid})`">
      <path
        d="M41.8594 27.0005H31.3464L21.9297 10.8157L27.9371 0.532118C28.0322 0.369982 28.1674 0.235656 28.3293 0.142359C28.4913 0.0490612 28.6744 0 28.8608 0C29.0472 0 29.2303 0.0490612 29.3923 0.142359C29.5542 0.235656 29.6894 0.369982 29.7844 0.532118L43.5415 24.1524C43.7089 24.4452 43.7959 24.778 43.7935 25.1162C43.7911 25.4544 43.6994 25.7859 43.5279 26.0763C43.3564 26.3667 43.1113 26.6055 42.818 26.7679C42.5246 26.9304 42.1937 27.0107 41.8594 27.0005Z"
        fill="#02C57F"
      />
      <path
        d="M21.8485 10.8157L12.4243 27.0005H1.91125C1.57886 27.0065 1.25083 26.9232 0.960706 26.7591C0.67058 26.5949 0.428764 26.3557 0.259973 26.0661C0.091182 25.7764 0.00147279 25.4466 1.79812e-05 25.1104C-0.00143683 24.7741 0.0854108 24.4435 0.251688 24.1524L14.0088 0.532118C14.1038 0.369982 14.239 0.235656 14.4009 0.142359C14.5629 0.0490613 14.746 0 14.9324 0C15.1188 0 15.302 0.0490613 15.4639 0.142359C15.6258 0.235656 15.761 0.369982 15.8561 0.532118L21.8485 10.8157Z"
        fill="#70E766"
      />
      <path
        d="M21.8888 20.3555L26.1841 27.001H17.5859L21.8888 20.3555Z"
        fill="#02C57F"
      />
      <path
        d="M85.7247 7.25114C84.555 6.53405 83.21 6.16306 81.8424 6.18026C80.528 6.16429 79.2328 6.49965 78.0877 7.15241C76.9805 7.77277 76.0417 8.65971 75.3543 9.73468C74.6568 8.64233 73.6994 7.74451 72.5698 7.12351C71.4401 6.5025 70.1745 6.17818 68.8888 6.18026C67.8863 6.18433 66.8947 6.39139 65.9726 6.78924C65.0504 7.18708 64.2164 7.76765 63.5196 8.49671V6.73469H58.7812V26.4815H63.5422V15.2866C63.5375 14.551 63.7267 13.8274 64.0903 13.1904C64.4566 12.5643 64.9732 12.0418 65.5922 11.6714C66.2132 11.2783 66.9322 11.0727 67.6648 11.079C68.3993 11.07 69.122 11.2672 69.7524 11.6486C70.3782 12.0101 70.8968 12.5346 71.2542 13.1676C71.6235 13.8217 71.8128 14.5638 71.8024 15.317V26.5119H76.5633V15.2866C76.5506 14.5312 76.7456 13.7871 77.1265 13.1372C77.4907 12.5095 78.0077 11.9865 78.6284 11.6182C79.2588 11.2368 79.9814 11.0396 80.716 11.0486C81.4492 11.0371 82.1697 11.2431 82.7886 11.641C83.4126 12.0048 83.9307 12.5288 84.2904 13.16C84.6712 13.7908 84.869 14.5172 84.8611 15.2562V26.4511H89.5695V14.003C89.5863 12.6208 89.2223 11.2612 88.5182 10.0765C87.8429 8.90727 86.8807 7.93412 85.7247 7.25114Z"
        fill="#010101"
      />
      <path
        d="M98.2453 6.73438H93.4844V26.4812H98.2453V6.73438Z"
        fill="#010101"
      />
      <path
        d="M109.621 0.539062H104.852V6.73653H101.969V11.5593H104.852V26.4834H109.621V11.5593H113.405V6.73653H109.621V0.539062Z"
        fill="#010101"
      />
      <path
        d="M141.667 11.3632C140.833 9.81146 139.617 8.50351 138.137 7.56574C136.67 6.6299 134.968 6.14203 133.234 6.16067H124.771C123.565 6.19126 122.378 6.46497 121.279 6.96574C120.098 7.50789 119.036 8.28245 118.155 9.24423C117.076 10.3832 116.262 11.7506 115.77 13.2473C115.279 14.744 115.123 16.3325 115.313 17.8978C115.504 19.4631 116.036 20.9659 116.872 22.2974C117.707 23.6289 118.825 24.7557 120.145 25.5961C121.534 26.4753 123.132 26.9582 124.771 26.9936H133.234C134.541 27.0025 135.835 26.7252 137.026 26.1809C138.195 25.6497 139.248 24.891 140.127 23.948C141.989 21.9692 143.018 19.3355 142.996 16.6037C143.016 14.7691 142.557 12.9616 141.667 11.3632ZM137.567 19.3759C137.156 20.1956 136.546 20.8961 135.794 21.4113C135.037 21.9228 134.144 22.1878 133.234 22.1708H124.771C123.982 22.1138 123.222 21.8522 122.563 21.4113C121.803 20.9041 121.18 20.2121 120.753 19.3987C120.29 18.5314 120.055 17.5587 120.07 16.5733C120.06 15.6058 120.287 14.6509 120.731 13.7936C121.149 12.9662 121.766 12.2585 122.525 11.7354C123.194 11.2796 123.973 11.017 124.778 10.9759H133.241C134.131 10.9692 135.003 11.2339 135.742 11.7354C136.513 12.2414 137.14 12.9433 137.559 13.7708C138.003 14.6299 138.235 15.5849 138.235 16.5544C138.235 17.5238 138.003 18.4788 137.559 19.3379L137.567 19.3759Z"
        fill="#010101"
      />
    </g>
    <defs>
      <clipPath :id="`logoWithText-${uuid}`">
        <rect width="143" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
