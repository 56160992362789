<template>
  <svg
    width="134"
    height="42"
    viewBox="0 0 134 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.8327 10.6599C40.9733 9.52137 38.835 8.93234 36.6609 8.95965C34.5714 8.9343 32.5124 9.46674 30.692 10.5031C28.9318 11.4881 27.4393 12.8963 26.3467 14.603C25.2378 12.8687 23.7157 11.4432 21.9199 10.4573C20.1242 9.47128 18.1121 8.95635 16.0682 8.95965C14.4745 8.96612 12.8982 9.29486 11.4322 9.92652C9.96626 10.5582 8.64037 11.48 7.53273 12.6375V9.83992H0V41.1921H7.56854V23.4178C7.56109 22.2499 7.86191 21.1011 8.44001 20.0897C9.02222 19.0956 9.84344 18.2661 10.8276 17.678C11.8147 17.0538 12.9578 16.7274 14.1224 16.7374C15.2901 16.7231 16.4389 17.0362 17.4411 17.6418C18.436 18.2157 19.2604 19.0485 19.8286 20.0535C20.4157 21.0919 20.7166 22.2703 20.7001 23.4661V41.2403H28.2686V23.4178C28.2484 22.2185 28.5584 21.0371 29.1639 20.0053C29.7428 19.0086 30.5648 18.1783 31.5515 17.5935C32.5537 16.9879 33.7025 16.6749 34.8702 16.6892C36.0358 16.6709 37.1813 16.9979 38.165 17.6297C39.1571 18.2074 39.9807 19.0393 40.5526 20.0414C41.1579 21.043 41.4723 22.1962 41.4599 23.3696V41.1439H48.9448V21.3799C48.9716 19.1854 48.3929 17.0267 47.2735 15.1457C46.2 13.2893 44.6704 11.7443 42.8327 10.6599Z"
      fill="currentColor"
    />
    <path
      d="M62.7341 9.83984H55.1655V41.192H62.7341V9.83984Z"
      fill="currentColor"
    />
    <path
      d="M80.8184 0H73.2379V9.83976H68.6538V17.4969H73.2379V41.192H80.8184V17.4969H86.835V9.83976H80.8184V0Z"
      fill="currentColor"
    />
    <path
      d="M131.77 17.183C130.444 14.7193 128.512 12.6427 126.159 11.1538C123.827 9.66794 121.121 8.89333 118.364 8.92294H104.91C102.994 8.9715 101.107 9.40608 99.359 10.2012C97.4822 11.0619 95.794 12.2917 94.3929 13.8187C92.6783 15.6271 91.3834 17.7981 90.602 20.1744C89.8206 22.5507 89.5723 25.0728 89.8752 27.5581C90.178 30.0433 91.0243 32.4293 92.3529 34.5433C93.6814 36.6573 95.4588 38.4463 97.5564 39.7807C99.7647 41.1766 102.306 41.9433 104.91 41.9995H118.364C120.442 42.0136 122.499 41.5734 124.392 40.7092C126.25 39.8658 127.926 38.6611 129.323 37.164C132.283 34.0222 133.918 29.8408 133.883 25.5034C133.914 22.5906 133.186 19.7209 131.77 17.183ZM125.252 29.9048C124.6 31.2063 123.63 32.3185 122.435 33.1365C121.231 33.9486 119.811 34.3694 118.364 34.3423H104.91C103.657 34.2519 102.448 33.8365 101.4 33.1365C100.192 32.3311 99.2027 31.2324 98.5233 29.941C97.7876 28.5641 97.4136 27.0197 97.437 25.4552C97.4218 23.9191 97.7827 22.403 98.4875 21.0418C99.1524 19.7282 100.134 18.6045 101.341 17.7739C102.404 17.0503 103.642 16.6333 104.922 16.5681H118.376C119.791 16.5575 121.176 16.9777 122.351 17.7739C123.577 18.5774 124.573 19.6917 125.24 21.0056C125.946 22.3696 126.315 23.8859 126.315 25.4251C126.315 26.9642 125.946 28.4805 125.24 29.8445L125.252 29.9048Z"
      fill="currentColor"
    />
  </svg>
</template>
