<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7651_813)">
      <path
        d="M9.6999 11.4001C8.9999 11.4001 8.3999 12.0001 8.3999 12.7001C8.3999 13.4001 8.9999 14.0001 9.6999 14.0001C10.3999 14.0001 10.9999 13.4001 10.9999 12.7001C10.9999 12.0001 10.3999 11.4001 9.6999 11.4001Z"
        fill="currentColor"
      />
      <path
        d="M14.3 11.4001C13.6 11.4001 13 12.0001 13 12.7001C13 13.4001 13.6 14.0001 14.3 14.0001C15 14.0001 15.6 13.4001 15.6 12.7001C15.6 12.0001 15 11.4001 14.3 11.4001Z"
        fill="currentColor"
      />
      <path
        d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM15.5 17C15.5 17 15 16.5 14.7 16C16.3 15.5 16.9 14.6 16.9 14.6C16.4 14.9 15.9 15.1 15.5 15.3C14.9 15.6 14.3 15.7 13.7 15.8C10.8 16.3 9.2 15.5 7.7 14.9L7.2 14.6C7.2 14.6 7.8 15.5 9.4 16C9 16.5 8.6 17 8.6 17C5.8 16.9 4.8 15.2 4.8 15.2C4.8 11.3 6.6 8.2 6.6 8.2C8.3 6.9 10 7 10 7L10.1 7.1C7.9 7.7 6.9 8.7 6.9 8.7C6.9 8.7 7.2 8.6 7.6 8.4C10.6 7.2 13.9 7.3 17 8.8C17 8.8 16 7.9 13.9 7.3L14.1 7C14.4 7 15.9 7.1 17.6 8.3C17.6 8.3 19.4 11.5 19.4 15.3C19.4 15.2 18.3 17 15.5 17Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7651_813">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
