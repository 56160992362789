import { TimeDuration } from '@shared/types'
import { WhitelistTab } from '../../types'
import home from './en/home'
import swap from './en/swap'
import vote from './en/vote'
import vault from './en/vault'
import manage from './en/manage'
import wallet from './en/wallet'
import reward from './en/reward'
import missions from './en/missions'
import launchpad from './en/launchpad'
import portfolio from './en/portfolio'
import vaultConfig from './en/vaultConfig'

export default {
  ...home,
  ...swap,
  ...vote,
  ...vault,
  ...wallet,
  ...reward,
  ...manage,
  ...missions,
  ...launchpad,
  ...portfolio,
  ...vaultConfig,
  common: {
    NA: 'NA',
    buy: 'Buy',
    new: 'New',
    max: 'Max',
    here: 'here',
    days: 'Days',
    back: 'Back',
    beta: 'Beta',
    copy: 'Copy',
    sell: 'Sell',
    type: 'Type',
    long: 'Long',
    short: 'Short',
    close: 'Close',
    hours: 'Hours',
    value: 'Value',
    token: 'Token',
    amount: 'Amount',
    cancel: 'Cancel',
    create: 'Create',
    action: 'Action',
    search: 'Search',
    vaults: 'Vaults',
    redeem: 'Redeem',
    noData: 'No Data',
    address: 'Address',
    minutes: 'Minutes',
    seconds: 'Seconds',
    details: 'Details',
    confirm: 'Confirm',
    status: 'Status',
    balance: 'Balance',
    newData: 'New Data',
    joinNow: 'Join Now',
    moreInfo: 'More Info',
    showMore: 'Show more',
    learnMore: 'Learn more',
    selectToken: 'Select token',
    transferNow: 'Transfer Now',
    readOurDocs: 'Read our docs',
    insufficientFee: 'Insufficient fee',
    backToPortfolio: 'Back to Portfolio'
  },

  selector: {
    token: {
      title: 'Select token',
      search: 'Search token'
    },

    market: {
      title: 'Select market',
      search: 'Search market'
    }
  },

  uploader: {
    title: 'Drop CSV file here!',
    selectFile: 'Select file',
    description: '',
    cta: 'Upload',
    addAddresses: 'Add addresses',
    removeAddresses: 'Remove addresses',
    downloadCSVTemplate: 'Download csv template',
    uploadDescription:
      'Upload the CSV file with the addresses that you want to whitelist, note that there is a 5000 address limit per upload.',
    removeDescription:
      'Upload the CSV file with the addresses that you want to remove from the whitelist, note that there is a 5000 address limit per upload.',
    toast: {
      exceedFileSize: 'File exceeded maximum size (500KB).',
      readerError: 'Failed to read file, please try again.',
      invalidType: 'Invalid type, only csv file are supported.',
      whitelistUpdated: 'Updated whitelist accounts successfully!',
      noChanges: 'No changes detected.'
    },
    [WhitelistTab.AddressesToAdd]: {
      title: 'Addresses to add'
    },
    [WhitelistTab.AddressesToRemove]: {
      title: 'Addresses to remove'
    },
    [WhitelistTab.InvalidAddresses]: {
      title: 'Invalid addresses'
    }
  },
  time: {
    endDate: 'End date',
    utc: 'UTC timestamp',
    startDate: 'Start date',
    milliSeconds: 'Milliseconds',
    [TimeDuration.Day]: 'day',
    [TimeDuration.Hour]: 'hour',
    [TimeDuration.Minute]: 'min',
    [TimeDuration.Second]: 'sec'
  },
  market: {
    market: 'Market',
    markets: 'Markets',
    marketType: 'Market Type',
    derivative: 'Derivative',
    perpetual: 'Perpetual',
    spot: 'Spot'
  },
  nav: {
    vote: 'Vote',
    swap: 'Swap',
    manage: 'Manage',
    vaults: 'Vaults',
    rewards: 'Rewards',
    settings: 'Settings',
    missions: 'Missions',
    launchpad: 'Launchpad',
    portfolio: 'Portfolio',
    playground: 'Playground',
    title: 'Grow your assets',
    leaderboard: 'Leaderboard'
  },
  clipboard: {
    address: 'Address copied to clipboard'
  },
  validation: {
    maxBalance: 'Amount exceed your balance'
  },
  pagination: {
    description: 'From {from} to {to} total {totalCount}'
  },
  geoRestricted: {
    cta: 'Return to homepage',
    title: 'Mito is not available in your region.',
    description:
      "We're unable to offer Mito in your region due to regulatory requirements. Our team is actively working to expand our service area while ensuring full compliance with local laws. We appreciate your interest and hope to welcome you to our growing global community soon."
  },
  maintenance: {
    title: 'System maintenance in progress',
    description: 'We are constantly improving Mito. Please come back later.'
  },
  notFound404: {
    title: 'Page Not Found',
    description: 'The page you are looking for does not exist.',
    backToHome: 'Back to home page'
  }
}
