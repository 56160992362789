<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7652_822)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.3593 6L12.9881 11.0812H12.9878L17.742 18H14.2455L11.0441 13.3409L7.0359 18H6L10.5842 12.6716L6 6H9.49645L12.5279 10.4119L16.3234 6H17.3593ZM11.1048 12.066L11.5693 12.7304V12.7306L14.7329 17.2557H16.3239L12.4471 11.7103L11.9827 11.046L9.00022 6.77981H7.40914L11.1048 12.066Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7652_822">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
