export const blogLink = 'https://blog.mito.fi/'
export const launchpadBlogLink =
  'https://blog.mito.fi/en/the-mito-launchpad-overview/'
export const docsLink = 'https://docs.mito.fi/'
export const twitterLink = 'https://twitter.com/mitofinance'
export const discordLink = 'https://discord.com/invite/mitofi'
export const telegramLink = 'https://t.me/mitofinance_official'
export const launchpadDocsLink = 'https://docs.mito.fi/launchpad/launchpad'
export const launchpadGoogleFormFeedbackLink =
  'https://forms.gle/yKWkHWjyeAN1cgcs6'
