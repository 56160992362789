<script lang="ts" setup>
import { Status } from '@injectivelabs/utils'
import { PropType } from 'vue'

const attrs = useAttrs()

const props = defineProps({
  isSm: Boolean,
  isMd: Boolean,
  isLg: Boolean,
  isXl: Boolean,
  isPrimary: Boolean,
  isLoading: Boolean,
  isDisabled: Boolean,
  isSecondary: Boolean,

  classes: {
    type: String,
    default: ''
  },

  status: {
    type: Object as PropType<Status>,
    default: () => new Status()
  }
})

const emit = defineEmits<{
  click: [event: Event]
}>()

const showSpinner = computed<boolean>(() => {
  return (props.status && props.status.isLoading()) || props.isLoading
})

const presetClasses = computed(() => {
  if (props.isPrimary) {
    return 'bg-green-600 hover:bg-green-800 text-white'
  }

  if (props.isSecondary) {
    return 'border border-green-600 text-green-600 hover:text-white hover:bg-green-600'
  }

  return ''
})

const filteredAttrs = computed(() => {
  const filteredAttrs = { ...attrs }

  const classes = `${(filteredAttrs.class as string) || ''} ${
    presetClasses.value
  }`

  /** Remove text|bg color from buttons when they are disabled */
  if (props.isDisabled || props.status.isLoading()) {
    const opacityClasses = []

    const filteredClass = classes
      .replace(/hover:bg-(\w+)/g, '')
      .replace(/hover:bg-(\w+)-(\d+)/g, '')
      .replace(/hover:text-(\w+)/g, '')
      .replace(/hover:text-(\w+)-(\d+)/g, '')

    if (!classes.includes('bg-opacity')) {
      opacityClasses.push('bg-opacity-50')
    }

    if (!classes.includes('text-opacity')) {
      opacityClasses.push('text-opacity-50')
    }

    if (!classes.includes('border-opacity')) {
      opacityClasses.push('border-opacity-50')
    }

    return { ...attrs, class: [filteredClass, ...opacityClasses].join(' ') }
  }

  return { ...attrs, class: classes }
})

const sizeClasses = computed<string>(() => {
  if (props.isSm) {
    return 'p-2 text-sm h-[30px] max-h-[30px]'
  }

  if (props.isMd) {
    return 'py-3 px-4 text-base h-10 max-h-10'
  }

  if (props.isLg) {
    return 'p-4 text-base h-12 max-h-12'
  }

  if (props.isXl) {
    return 'p-4 text-xl h-[52px] max-h-[52px]'
  }

  return 'py-2 px-4 text-sm h-8 max-h-8'
})

function click(event: Event) {
  if (props.status && props.status.isLoading()) {
    return
  }

  emit('click', event)
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<template>
  <button
    type="button"
    class="flex items-center rounded-lg font-semibold outline-none justify-center"
    :class="[
      classes,
      sizeClasses,
      {
        'cursor-not-allowed': isDisabled
      }
    ]"
    :disabled="isDisabled || status.isLoading()"
    v-bind="filteredAttrs"
    @click="click"
  >
    <slot v-if="showSpinner" name="spinner">
      <span class="block w-full">
        <AppSpinner
          :is-sm="!isMd && !isLg && !isXl"
          :is-lg="isXl"
          :primary="isSecondary"
        />
      </span>
    </slot>

    <slot v-else />
  </button>
</template>
